<template>
  <v-app-bar color="teal-darken-4" >
    <v-app-bar-nav-icon></v-app-bar-nav-icon>
    <v-app-bar-title>ArTra DB</v-app-bar-title>
  </v-app-bar>
</template>

<script>
export default {
  name: 'NavBar'
}
</script>