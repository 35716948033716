<template>
  <v-footer class="bg-teal-darken-4">
    <v-col class="text-center">
      {{ new Date().getFullYear() }} — <strong>artraDB</strong>
    </v-col>
  </v-footer>
</template>

<script>
  export default {
    name: 'Footer',
  }
</script>